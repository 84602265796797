import { useEffect } from "react";
import type { AxiosError } from "axios";
import { Form, Link, useNavigation, useActionData } from "@remix-run/react";
import {
  redirect,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import { useTranslation } from "react-i18next";
import {
  Button,
  Stack,
  Text,
  TextField,
  useToast,
} from "~/components/vendorUI";
import { css } from "ui/css";
import { getSession } from "app/utils/sessions.server";
import { z } from "zod";
import { isEmailValid } from "~/utils/is_email_valid";
import { catchError, LEVEL } from "~/utils/catchError";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { action as loginAction } from "app/routes/_auth+/api/login/index";

const formSchema = z.object({
  email: z.string().refine(isEmailValid),
  password: z.string().min(3),
});

export const action = async (args: ActionFunctionArgs) => {
  try {
    return await loginAction(args);
  } catch (error) {
    return error;
  }
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"));
  if (Object.keys(session.data).length > 0) return redirect("/");

  return null;
};

export default function Login() {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const error = useActionData<typeof action>();
  const navigation = useNavigation();
  const submitting = navigation.state === "submitting";
  const form = useForm({ resolver: zodResolver(formSchema) });
  const isValid = form.formState.isValid;

  useEffect(() => {
    if (!error) return;

    const email = navigation.formData?.get("email");
    const UNAUTHORIZED = 401;
    const TOO_MANY_REQUESTS = 429;

    const status = (error as AxiosError)?.response?.status;
    if (status !== UNAUTHORIZED) {
      catchError(error, {
        email,
        level: LEVEL.WARNING,
        reason: "[User Access] Login",
      });
    }

    let message = t("LOGIN_FORM_ERROR_REQUEST");
    if (status === TOO_MANY_REQUESTS) {
      message = t("ERROR_ATTEMPT-LIMIT-EXCEEDED");
    }
    addToast(message, { type: "error" });
  }, [error]);

  return (
    <Stack className={wrapperCSS} gap="40px">
      <Text color="brand" weight="bold" size="28px">
        {t("LOGIN_PAGE_TITLE")}
      </Text>
      <Form replace method="POST" role="form" data-tracking="login_page_login">
        <Stack asChild>
          <fieldset
            className={css({ border: "0 none" })}
            style={{ opacity: submitting ? 0.5 : 1 }}
            disabled={submitting}
          >
            <Stack gap="28px">
              <TextField
                id="email"
                type="email"
                label={t("LOGIN_FORM_LABEL_EMAIL")}
                {...form.register("email")}
              />
              <TextField
                id="password"
                type="password"
                label={t("LOGIN_FORM_LABEL_PASSWORD")}
                {...form.register("password")}
              />
            </Stack>
            <Stack gap="16px" align="end">
              <Button
                variant="primary"
                type="submit"
                disabled={!isValid || submitting}
                isLoading={submitting}
                data-tracking="login_page_login"
              >
                {t("ACTION_LOGIN")}
              </Button>
              <Link to="/forgot_login" data-tracking="login_page_forgot_login">
                {t("LOGIN_ACTION_FORGOT-LOGIN")}
              </Link>
            </Stack>
          </fieldset>
        </Stack>
      </Form>
      <Stack gap="16px">
        <Text color="brand" size="14px" weight="regular">
          {t("LOGIN_LABEL")}
        </Text>
        <a
          target="_blank"
          href="https://www.gartner.com/en/digital-markets/capterra-provider-signup"
          className="gdm-link-default"
          rel="noopener noreferrer"
          data-tracking="login_page_get_free_listings"
        >
          {t("LOGIN_ACTION_REGISTER")}
        </a>
      </Stack>
    </Stack>
  );
}

const wrapperCSS = css({
  maxWidth: "400px",
  marginInline: "auto",
  marginBlock: "80px",
});
